import axios from 'axios';

import type { IApiResponse, ICurrentUserData } from '@/types';

export function getCurrentUserAccount(token?: string) {
  const config = token
    ? {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    : {};
  return axios.get<IApiResponse<ICurrentUserData>>('user/account', config);
}
