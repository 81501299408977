import axios from 'axios';

import { getCurrentUserAccount } from '../api/user/getCurrentUserAccount';
import { AUTH_TOKEN_COOKIE_NAME } from '../config/auth';
import { deleteCookie, getCookie } from '../lib/cookies';

export function getCurrentUserQueryKey(token?: string) {
  return ['current-user', token];
}

export function currentUserQuery() {
  const token = getCookie(AUTH_TOKEN_COOKIE_NAME);

  return {
    queryKey: getCurrentUserQueryKey(token),
    queryFn: async () => {
      if (!token) {
        return null;
      }
      try {
        const response = await getCurrentUserAccount(token);
        return response.data.data;
      } catch (error) {
        deleteCookie(AUTH_TOKEN_COOKIE_NAME);
        if (axios.isAxiosError(error) && error.response?.status === 401) {
          return null;
        }
        console.error(error);
        throw error;
      }
    },
    staleTime: Number.POSITIVE_INFINITY,
  };
}
